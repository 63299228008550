<template>
  <base-section id="legal">
    <v-container>
      <v-row>
        <base-subheading
          size="display-1"
          title="Legal Disclosure"
          space="0"
          class="primary--text"
        />
      </v-row>

      <v-row
        v-for="(card,i) of cards"
        :key="`card-${i}`"
      >
        <v-col
          cols="12"
        >
          <base-title
            :title="card.title"
            tag="div"
          />

          <base-body v-html="card.text">
            {{ card.text }}
          </base-body>
          <base-body
            v-for="sub in card.subtext"
            :key="sub"
          >
            <base-title
              :title="sub.title"
              tag="div"
            />
            {{ sub.text }}
          </base-body>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'LegalNotice',

    data: () => ({
      cards: [
        {
          title: 'Information in accordance with Section 5 TMG',
          text: 'Aptamimetics GmbH<br>Birkenweg 2a<br>79252 Stegen',
        },
        {
          title: 'Represented by',
          text: 'Dr. Peter Siegert',
        },
        {
          title: 'Contact Information',
          text: 'Phone: +49 151 685 692 82<br>Mail: contact@aptamimetics.com<br>Web: <a href="https://www.aptamimetics.com" target="_blank">https://www.aptamimetics.com</a>',
        },
        {
          title: 'Register entry',
          text: 'Entry in: Handelsregister<br>Register number: HRB 722942<br>Register Court: Amtsgericht Freiburg i. Br.',
        },
        {
          title: 'VAT ID according to § 27a UStG',
          text: 'DE337044804',
        },

        {
          title: 'Terms of service',
          text: 'The information provided on this website is provided on an “as-is” basis without warranties of any kind either express or implied. Aptamimetics and its authors make no warranties or representations of any kind concerning any information contained in this website. This website is provided only as general information. The authors expressly disclaim all liability with respect to actions taken or not taken based upon the information contained herein or with respect to any errors or omissions in such information.',
        },
      ],

    }),
  }
</script>
